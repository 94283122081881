body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #F7AE2B;
}

a:hover {
  color: #985f0d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Arial;
}

.navbar {
  margin-bottom: 20px;
}

.DropPDF .glyphicon {
  font-size: 4.5em;
}

.DropPDF {
  border: 2px dashed rgb(102, 102, 102);
  border-radius: 5px;
  font-size: 2.5em;
  margin-bottom: 30px;
}

.DropPDF p {
  padding: 0 10px;
  font-weight: bold;
}

.add_margin_bottom_10 {
  margin-bottom: 10px !important;
}

.add_margin_bottom_20 {
  margin-bottom: 20px !important;
}

.add_margin_bottom_30 {
  margin-bottom: 30px !important;
}

.add_margin_bottom_40 {
  margin-bottom: 40px !important;
}

.add_margin_bottom_50 {
  margin-bottom: 50px !important;
}

.add_margin_bottom_60 {
  margin-bottom: 60px !important;
}

h1,
h2,
h3 {
  font-weight: bold;
}

h2 {
  font-size: 40px;
}

.btn-jumbo {
  font-weight: bold;
  border-radius: 6px;
  color: #fff;
  background-color: #F7AE2B;
  border-color: #eea236;
  width: 100%;
  margin-bottom: 20px;
}

.btn-jumbo:focus,
.btn-jumbo.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-jumbo:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-jumbo:active,
.btn-jumbo.active,
.open>.dropdown-toggle.btn-jumbo {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-jumbo:active:hover,
.btn-jumbo.active:hover,
.open>.dropdown-toggle.btn-jumbo:hover,
.btn-jumbo:active:focus,
.btn-jumbo.active:focus,
.open>.dropdown-toggle.btn-jumbo:focus,
.btn-jumbo:active.focus,
.btn-jumbo.active.focus,
.open>.dropdown-toggle.btn-jumbo.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-jumbo:active,
.btn-jumbo.active,
.open>.dropdown-toggle.btn-jumbo {
  background-image: none;
}

.btn-jumbo.disabled:hover,
.btn-jumbo[disabled]:hover,
fieldset[disabled] .btn-jumbo:hover,
.btn-jumbo.disabled:focus,
.btn-jumbo[disabled]:focus,
fieldset[disabled] .btn-jumbo:focus,
.btn-jumbo.disabled.focus,
.btn-jumbo[disabled].focus,
fieldset[disabled] .btn-jumbo.focus {
  background-color: #F7AE2B;
  border-color: #eea236;
}

.btn-jumbo .badge {
  color: #F7AE2B;
  background-color: #fff;
}

.download_arrow {
  margin-top: 30px;
}

.add_color_general {
  color: #999;
}

.success_message {
  color: #99CA3B;
}

.failure_message {
  color: #ca3b3b;
}

@media (min-width: 768px) {
  .row {
    position: relative;
  }

  .bottom-align-text {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .float-right-sm {
    float: right;
  }
}

@media (max-width: 480px) {
  .btn-jumbo {
    font-size: 14px;
  }

  .text-center-xs {
    text-align: center !important;
  }
}

.small {
  font-size: small;
}